
.footer {
    background-image: linear-gradient( 44.8deg,  rgba(255,221,136,0.28) -53.1%, rgba(255,136,102,0.67) 49% );
    padding-top: 35px;
    width: 100%;
    max-width: 100%;
  }
  
.footer-text { 
    padding-top: 10px;
    margin-top: 15px;
}

.footer .footer-text, .footer-copyrights, .footer-row-elements{
    display: flex;
    justify-content: center;
    align-items: center;
}
