* {
  font-family: 'OpenDyslexic3Regular', 'Arial', "Verdana", 'Tahoma', 'Trebuchet MS',' Calibri';
  font-size: 14px;
  letter-spacing: 1.25px;    /* 35% of  average letter width*/
  word-spacing: 0.875rem;  /* default value * 350% */
  line-height: 30px;    /* default value * 150% */
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
}

h2, h3, h1 {
  overflow-y: hidden;
}

.background {
  background-image: linear-gradient( 44.8deg,  rgba(255,136,102,0.67) -53.1%, rgba(255,221,136,0.28) 49% ); 
}

.App {
  text-align: center;
}

.component-body {
  padding-top: 50px;
  text-align: center;
  width: 100%;
  padding-left: 40px;
}

.tts-button {

  padding-top: 30px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#tts {
  width: 80%;
  height: 60vh;

  @media only screen and (max-width: 600px) {
    height: 90vh;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.nav-item {
  margin-right: 35px;
}

#brain{
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
}